import React, { useState } from 'react';
import { Image, Text, Box, forwardRef } from "@chakra-ui/react";
import { ReactComponent as ArrowDown } from '../Assets/down.svg';
import { motion, isValidMotionProp } from "framer-motion";
import {useSpring, animated} from 'react-spring';





function TextWithin(){

    const MotionBox = motion.custom(
        forwardRef((props, ref) => {
          const chakraProps = Object.fromEntries(
            // do not pass framer props to DOM element
            Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
          )
          return <Box ref={ref} {...chakraProps} />
        }),
      )



    return(
        <div style={{position: "absolute"}}>
        {/* <Box position="absolute" 
            w={["100vw", "100vw", "100vw", "15em"]}
            px={[4, 50, 50, 120]} 
            py={50} 
            // bottom={{base: "3.3em", md: "2.5em"}}
            bottom={["6em", "7em", "20%", "3.5em"]}
            zIndex="10" 
            color={"white"} 
            fontWeight={800}
            fontSize={[ 30, 25, 35, 60]}
            lineHeight={["45px", "45px", "45px","75px"]}> 
         
                
                Empowering the Innovative Spirit of the Filipino
            </Box>
            <Box position="absolute" 
            w={["100vw", "100vw", "100vw", "23em"]}
            px={[4, 50, 50, 120]} 
            py={50} 
            // bottom={{base: "3.3em", md: "2.5em"}}
            bottom={["5em", "5em", "5em", "4em"]}
            zIndex="10" 
            color={"white"} 
            fontWeight={800}
            display={["block", "none", "none", "block"]}
            fontSize={{base: 20, sm: 30, md: 30}}
            lineHeight={{base: "60px", sm: "60px", md: "60px"}}> 
                Let's Innovate
                
            </Box> */}

            <MotionBox 
            animate={{y: [0, 30, 30, 0]}}
            transition={{ duration: 2, ease: "easeInOut", loop: Infinity}}
            pos="absolute" 
            px={{md: 120, base: 8}}  
            display={["block", "none", "none", "block"]}
            bottom={["5em", "7em", "7em", "6em"]}>

                <ArrowDown height="44px" width="44px" />
            </MotionBox>

        
        </div>
    )
}


export default function HeroMain(props){
    const [loaded, setLoaded] = useState(false);
    
    const spring = useSpring({opacity: 1, from: {opacity: 0}})

    return(
        <div style={{backgroundColor: "black"}}>

        <animated.div style={spring}>
                <div style={{width: '100vw', height: '90vh',background: "rgba(0,0,0,0.1)", position: 'absolute' }} />
       

            {loaded ? null : 
            <div style={{
                height: '100vh',
                width: '100vw',
                backgroundColor: 'black'
            }}></div>}
            

         
      
                <Image src={props.image} 
                alt="innovate-ph" 
                onLoad={() => (setLoaded(true))} 
                style={ loaded ? {width: '100vw', height: '90vh', objectFit: 'cover'} : { display: 'none', opacity: "0" }}
                />
         
       
                

                <div style={{position: "relative"}}>
                    <TextWithin/>
                </div>

                </animated.div>
          

        </div>
       
    )
}