import React from 'react';
import { Container } from "@chakra-ui/react";
import BoxContainer from "./Sub-Components/BoxContainer";












export default function TextImage(props){
    return(
        <>
            <Container maxW={["92vw", "92vw", "84vw"]} p={["0px", "1rem", "1rem"]}>
                <BoxContainer tagline={props.tagline} title={props.title} text={props.text} image={props.image} font={props.font} />
            </Container>
  
        </>
    )
}