import  React, {useState} from "react";
import { Box, Container, Grid, Button, Text, GridItem, Image, Input, useToast } from "@chakra-ui/react";
import { ReactComponent as FB } from "../Assets/facebook.svg";
import { ReactComponent as IG } from "../Assets/instagram.svg";
import { ReactComponent as E } from "../Assets/email.svg";
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';




export default function Footer(){
    const toast = useToast();
    const initialValues = {
        email: '',
    }

    const [values, setValues] = useState(initialValues);
    const [isLoading, setLoading] = useState(false);

    const handleChange = e => {
        var { name, value } = e.target;
        setValues({
            ...values, 
            [name]: value,
        })

    }


    const sendEmail = async(e, props) => {
        e.preventDefault();


        setLoading(true)
        

        


        await emailjs.send('innovate_ph', 'template_qz6k3mj', {from_email: values.email}, 'user_75Bsee9ECUBAtheNEzbIL')
        .then((result) => {
            console.log('Email Sent!');
        }, (error) => {
            console.log(error.text);
        });



        console.log("done");

    
        toast({
          title: "Thank you.",
          description: "Thank you for your interest.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });


        setLoading(false);
    
  
    
        setValues({...initialValues});
        e.target.reset();
    
      }








    return(
        <>
           
                <Box w={"100vw"} bg={"#0B0D17"}>
                    <Container maxW={["92vw", "84vw", "84vw"]}  pt={"95px"} >

                    
                        <Grid templateColumns={[ "repeat(1, 1fr)", "repeat(1, 1fr)","repeat(1, 1fr)", "repeat(6, 1fr)"]} gap={[0, 0, 0, 6]} pb={["80px", "160px", "160px"]}>

                        <Box>

                        <Text fontSize="24px" fontWeight="700" color="white" w={["230px", "230px", "220px", "350px"]}>
                                <Image src="https://d1qwje3ak9jfe8.cloudfront.net/LOGO-INC.png" h={["40px", "40px", "40px", "50px"]} />
                            </Text>
                            
                            
                                    <Grid templateColumns="repeat(3, 1fr)" color="white" float={["left", "left", "left"]} pl={[0, 0, 0]} mt="20px" textAlign={["center", "end", "end"]}  pt={[2, 0, 0]}  gap={6}>
                                        <Text>
                                            <a href="https://www.facebook.com/innovate.com.ph/" target="_blank">
                                                <FB height="34" width="34" />
                                            </a>
                                        </Text>
                                        <Text>
                                            <a href="https://www.instagram.com/innovate.com.ph/" target="_blank">
                                                <IG height="34" width="34" />
                                            </a>
                                        </Text>
                                        <Text>
                                            <a href="mailto:team@innovate.com.ph" target="_blank">
                                                <E height="34" width="34" />
                                            </a>
                                        </Text>
                        
                                        
                                    </Grid>


                                    <Box mt={["105px", "105px", "105px", "95px"]}>
                                        <Text color="white" mb="15px" fontWeight="700">Sign up for updates from the PCCI Innovation Center</Text>
                                        



                                        <form onSubmit={sendEmail}>

                                        <Grid templateColumns={[ "70% 30%"]} gap={2}>
                                            <Input onChange={handleChange} type="email" name="email" placeholder="Email" color="white" border="2px solid"></Input>
                                            <Button  
                                                
                                                disabled={!values.email}
                                                isLoading={isLoading}  
                                                type="submit"
                                                bg="#BA2A2A"
                                                    _hover={{ bg: "#A72222" }}
                                                    _focusWithin={{ bg: "#921D1D" }}
                                                    size="md" 
                                                    color="white"
                                               
                                                    w={"full"} >
                                                    Submit
                                                </Button>
                                        </Grid>
                                        </form>
                                        
                                    </Box>



                             

                    


                                    
                                </Box>
                

                            <Box/>
                            <Box/>
                            <GridItem colSpan={[2, 2, 2, 1]}>
                                <Box  mt={8}> 
                                    <Text fontSize="xl" color="white">
                                        Site Map
                                    </Text>
                                    <Text fontSize="sm" color="white" mt={6}>
                                        <Link to="/">              
                                            Home
                                        </Link>  
                                        
                                    </Text>
                                    <Text fontSize="sm" color="white" mt={3}>
                                        <Link to="/facility"> 
                                            Facilities
                                        </Link>  
                                    </Text>
                                    <Text fontSize="sm" color="white" mt={3}>
                                        <Link to="/vision"> 
                                            AMBISYON 2040
                                        </Link> 
                                    </Text>
                                    <Text fontSize="sm" color="white" mt={3}>
                                        <Link to="/events"> 
                                            Events
                                        </Link> 
                                    </Text>
                                    <Text fontSize="sm" color="white" mt={3}>
                                        <Link to="/press"> 
                                            Press
                                        </Link> 
                                    </Text>
                                    <Text fontSize="sm" color="white" mt={3}>
                                        <Link to="/partners"> 
                                            Partners
                                        </Link> 
                                    </Text>
                                    <Text fontSize="sm" color="white" mt={3}>
                                        <Link to="/contact"> 
                                            Contact Us
                                        </Link> 
                                    </Text>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={2}>
                                <Box  mt={8}> 
                                    <Text fontSize="xl" color="white">
                                        Information
                                    </Text>
            
                                    <Text fontSize="sm" color="white" mt={6}>
                                        8 Meridian Avenue, <br/>
                                        11th Floor, DD Meridian Park, <br/>
                                        Cor. Macapagal Avenue, <br/>
                                        Bay Area, Pasay City. 1300
                                    </Text>
                                    <Text fontSize="sm" color="white" mt={3}>
                                        Tel. No: +632 8442 5015 loc. 215
                                    </Text>
          
                                </Box>
    
                            </GridItem>

                
                        </Grid>
         
                    </Container>
                    <Box bg={"#000102"} py={[7]} >
                        <Container maxW={["92vw", "84vw", "84vw"]} px={0}>
                            <Grid templateColumns={{sm: "repeat(1, 1fr)", md: "repeat(4, 1fr)"}} gap={"0 1.5rem"} >
                                <Text color="white" fontSize="sm" textAlign={["center", "left", "left"]}>
                                    © 2021 Innovate. All rights reserved
                                </Text>
                                <Box/>
                                <Box/>
         


                            </Grid>
                        </Container>

                    </Box>
                </Box>
          
        </>
    )
}