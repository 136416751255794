import React from 'react';
import { Container, Box } from "@chakra-ui/react";
import BoxContainerWhite from "../Components/Sub-Components/BoxContainerWhite";



export default function TextImageBlue(props){
    return(
        <>
        <Box background="#125394" w="100vw" h={["auto", "auto"]} py={[6, 6, 6, 10]}>
            <Container maxW={["92vw", "92vw", "84vw"]} p={["0px", "1rem", "1rem"]} >
                <BoxContainerWhite  title={props.title} text={props.text} image={props.image} font={props.font} />
            </Container>
        </Box>
        </>
    )
}