import React from 'react';
import { Container, Box, Grid, Image, Text } from "@chakra-ui/react";


export default function BeyondFour(){
    return(
        <>

            <Container maxW={["92vw", "92vw", "84vw"]} p={["0px", "1rem", "1rem"]}>

                <Box pb={{base: "90px", md: "90px"}}>
                {/* <Text 
            fontSize={["27px", "36px", "36px"]}
            fontWeight={900}
        
            // px={["calc(100vw - 96vw)", "calc(100vw - 91vw)", "calc(100vw - 91vw)"]}
            >
                BEYOND THE CENTER
            </Text> */}
                    <Grid templateColumns={{sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={8} py={[6, 6, 6, 10]}>
                        <Box pt={4}>
                            <Image src="https://d1qwje3ak9jfe8.cloudfront.net/DSC00353-min.JPG" w="100%" htmlHeight="370px" h={"370px"} objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                EDUCATION
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Providing workshops on a variety of topics in technology(AI, Machine Learning, Cloud Software, Big Data Analytics, Internet of Things, etc.) as well as entrepreneurship.
                            </Text>
                        </Box>
                        <Box pt={4}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/collab.jpg" w="100%" htmlHeight="370px" h={"370px"} objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                COLLABORATION
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Linking startups to other businesses to increase perspective, depth, and application.
                            </Text>
                        </Box>

                        {/* <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/imghere1.png" w="100%" htmlHeight="370px" h={"370px"} objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                EMPLOYMENT OPPORTUNITIES
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Promising individuals who receive training and education through our facilities may be selected for potential employment opportunities from our partners in local and multinational tech companies. 
                            </Text>
                        </Box> */}
                    </Grid>
                </Box>
                
            </Container>
          
        </>
    )
}