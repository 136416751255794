import React, { useEffect } from 'react';
import { Box, Container, Text } from '@chakra-ui/react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import FullCarouseEvents from '../Components/Sub-Components/FullCarouselEvents';
import axios from 'axios';
import Calendar from "@ericz1803/react-google-calendar";
import Album from '../Components/Album';








const API_KEY = "AIzaSyDd9ovNHzNgHMQPYbP8nRghIjHIZqUU1mE";
let calendars = [{ calendarId: "c_dprktv8nlsjlsjdiochtv424r8@group.calendar.google.com" }];





export default function Events(){





    return(
        <>
            <Container mt={12}  maxW={["92vw", "92vw", "84vw"]} p={["0px", "1rem", "1rem"]}>
                
                <Text 
                    fontSize="36px"
                    fontWeight={900}
                    textAlign="center"
                    pb={10}>
                        Events
                    </Text>
                    <Box px={[0,0,0,10]} pb={20}>
                        <Calendar apiKey={API_KEY} calendars={calendars}   />
                    </Box>


                
                    <Text 
                    fontSize="36px"
                    fontWeight={900}
                    textAlign="center"
                    pb={10}>
                        Past Events
                    </Text>
            <FullCarouseEvents />

            <Box >
                <Album/>

            </Box>



            <Box  mb="90px" />
            </Container>

        </>
    )
}