import React from 'react';
import HeroMain from '../Components/Hero';
import TextImage from '../Components/TextImage';
import ThreePillar from '../Components/Sub-Components/ThreePillar';
import Footer from '../Components/Footer';
import ColorBack from '../Components/ColorBack';
import BeyondFour from '../Components/Sub-Components/BeyondFourSection';
import TextImageBlue from '../Components/TextImageBlue';
import { Box } from '@chakra-ui/react';



export default function HomePage(){
    return(
        <>

            <Box display={["none", "none", "none", "block"]}>
                <HeroMain image="https://d1qwje3ak9jfe8.cloudfront.net/PCCI Innovation email-min.jpg" />
            </Box>
            <Box display={["block", "block", "block", "none"]}>
                <HeroMain image="https://d1qwje3ak9jfe8.cloudfront.net/vertical logo -min.png" />
            </Box>
            <TextImage 
            // tagline="Manila, Philippines"
            title="Silicon Valley startup experience"
            text={<>The PCCI Innovation Center is a start-up incubator built to give the Filipino more opportunities to learn about in-demand skills in fields like AI, Robotics, Coding, Big Data Analytics, Internet of Things, and Blockchain. With our partners from the PCCI and Huawei, the center provides tailored curriculums and certification programs to facilitate learning opportunities for entrepreneurs, established businesses, start-ups, and LGUs. These courses will provide the training and experience for the Filipino to be ready and qualified to enter employment in high technology companies, improving the skilled labor in that industry. The PCCI Innovation Center is a one-stop shop that promotes innovation by preparing the next generation to thrive in a more technology driven workforce.</>}
            image="https://d1qwje3ak9jfe8.cloudfront.net/DSC00569-min.JPG" 
            />

            {/* <ColorBack/> */}
            

            <TextImageBlue 
            title={<> An Ideal <br/> Innovation Center   </>}
            text={<>Located in Pasay City, Metro Manila, the 1000 sqm PCCI Innovation Center provides a state-of-the-art facility complete with meeting rooms, conference halls, and workstations that are designed to enable entrepreneurs and businesses to develop their ideas and projects. The center is equipped and prepared to facilitate collaboration, partnership, and learning opportunities in both the technology and business fields.</>}
            image="https://d1qwje3ak9jfe8.cloudfront.net/An ideal innovation center-min.jpeg" 
            />

            {/* <div style={{position: "relative"}}>
                <ThreePillar/>
            </div> */}

            <BeyondFour/>
           
    
        </>
    )
}