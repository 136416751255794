import  React, {useState, useEffect} from "react";
import { Box, Container, Grid, Text, Image, Link, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, useDisclosure } from "@chakra-ui/react";
import ImageText from "../Components/ImageText";
import Form from "../Components/Sub-Components/SignupForm";



export default function Press(){


    return(
        <>
        <Container maxW={["92vw", "92vw", "84vw"]} p={["0px", "1rem", "1rem"]} >
            <Box pt="30px" pb="90px">
                <Text fontSize="45px" fontWeight="900" textAlign="center" data-aos="fade" >
                    Press
                </Text>
                <Grid pt="40px" templateColumns={[ "repeat(1, 1fr)", "repeat(1, 1fr)","repeat(1, 1fr)", "repeat(3, 1fr)"]} gap={10}>
                    

                <Link href="https://www.philstar.com/business/2021/01/24/2072522/pcci-taps-huawei-innovation-center" isExternal>

                    <Box   border="1px solid #d2d2d2" borderRadius="10px">
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/DSC01519-min.JPG" w="full" h="205px" borderRadius="10px 10px 0 0"/>
                        <Box p="6">
                            <Text fontSize="12px" fontWeight="800" pb="5px">
                                The Philippine Star
                            </Text>
                            <Text fontSize="18px" fontWeight="800">
                                PCCI taps Huawei for innovation center
                            </Text>
                        </Box>
                 
                    </Box>
                 </Link>


                <Link href="https://mb.com.ph/2021/01/24/pcci-huawei-launch-innovation-center/" isExternal>

                    <Box   border="1px solid #d2d2d2" borderRadius="10px">
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/DSC01519-min.JPG" w="full" h="205px" borderRadius="10px 10px 0 0"/>
                        <Box p="6">
                            <Text fontSize="12px" fontWeight="800" pb="5px">
                                Manila Bulletin
                            </Text>
                            <Text fontSize="18px" fontWeight="800">
                                PCCI, HUAWEI Launch Innovation Center
                            </Text>
                        </Box>
                 
                    </Box>
                 </Link>


                <Link href="https://mb.com.ph/2021/01/22/pcci-taps-huawei-as-tech-partner-for-innovation-center/" isExternal>

                    <Box   border="1px solid #d2d2d2" borderRadius="10px">
                        <Image src="https://mb.com.ph/wp-content/uploads/2020/12/PCCI-president-Benedicto-Yujuico-1.jpg"  w="full"  h="205px" objectFit="cover" borderRadius="10px 10px 0 0"/>
                        <Box p="6">
                            <Text fontSize="12px" fontWeight="800" pb="5px">
                                Manila Bulletin
                            </Text>
                            <Text fontSize="18px" fontWeight="800">
                                PCCI taps Huawei as tech partner for innovation center
                            </Text>
                        </Box>
                 
                    </Box>
                 </Link>



                <Link href="https://www.bworldonline.com/pcci-sets-up-venue-for-teaching-robotics-entrepreneurship/" isExternal>

                    <Box   border="1px solid #d2d2d2" borderRadius="10px">
                        <Image src="https://www.bworldonline.com/wp-content/uploads/2020/10/SF_PCCI-VOB-Logo-Colored.jpg"  w="full"  h="205px" objectFit="cover" borderRadius="10px 10px 0 0"/>
                        <Box p="6">
                            <Text fontSize="12px" fontWeight="800" pb="5px">
                                Business World
                            </Text>
                            <Text fontSize="18px" fontWeight="800">
                                PCCI sets up venue for teaching robotics, entrepreneurship
                            </Text>
                        </Box>
                 
                    </Box>
                 </Link>

                <Link href="https://www.pna.gov.ph/articles/1128090" isExternal>

                    <Box   border="1px solid #d2d2d2" borderRadius="10px">
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/DSC01519-min.JPG" w="full" h="205px" borderRadius="10px 10px 0 0"/>
                      
                        <Box p="6">
                            <Text fontSize="12px" fontWeight="800" pb="5px">
                            Philippine News Agency
                            </Text>
                            <Text fontSize="18px" fontWeight="800">
                            PCCI opens innovation center
                            </Text>
                        </Box>
                 
                    </Box>
                 </Link>



                <Link href="https://malaya.com.ph/index.php/news_business/pcci-sets-up-innovation-hub/" isExternal>

                    <Box   border="1px solid #d2d2d2" borderRadius="10px">
                      
                        <Box p="6">
                            <Text fontSize="12px" fontWeight="800" pb="5px">
                            Malaya Business Insight
                            </Text>
                            <Text fontSize="18px" fontWeight="800">
                                PCCI sets up innovation hub
                            </Text>
                        </Box>
                 
                    </Box>
                 </Link>



                <Link href="https://www.manilatimes.net/2021/01/22/business/business-top/pcci-unveils-innovation-center/830665/" isExternal>

                    <Box   border="1px solid #d2d2d2" borderRadius="10px">
                      
                        <Box p="6">
                            <Text fontSize="12px" fontWeight="800" pb="5px">
                            The Manila Times
                            </Text>
                            <Text fontSize="18px" fontWeight="800">
                            PCCI unveils innovation center
                            </Text>
                        </Box>
                 
                    </Box>
                 </Link>


   

                <Link href="https://www.atinitonews.com/2021/01/pcci-taps-huawei-as-tech-partner-for-innovation-center/" isExternal>

                    <Box   border="1px solid #d2d2d2" borderRadius="10px">
                      
                        <Box p="6">
                            <Text fontSize="12px" fontWeight="800" pb="5px">
                            Atin Ito News
                            </Text>
                            <Text fontSize="18px" fontWeight="800">
                            PCCI TAPS HUAWEI AS TECH PARTNER FOR INNOVATION CENTER
                            </Text>
                        </Box>
                 
                    </Box>
                 </Link>




                  <Link href="https://mail.google.com/mail/u/6?ui=2&ik=93d68c0f41&attid=0.1.1&permmsgid=msg-f:1689937793239991833&th=1773dd782293c619&view=fimg&sz=s0-l75-ft&attbid=ANGjdJ-WsiHB1hP_9nopIHAmyx22nTmXiVMy96toN8Rnn2RhWhtTW9pbTZ9W3ubBGGvDl6zzqNremta3MTC4J6k3pYbbNm2_sOrRAYRwU3VSPdB07WDJpEl19h6xsok&disp=emb" isExternal>

                        <Box   border="1px solid #d2d2d2" borderRadius="10px">
                        
                            <Box p="6">
                                <Text fontSize="12px" fontWeight="800" pb="5px">
                                The Philippine Star
                                </Text>
                                <Text fontSize="18px" fontWeight="800">
                                    PCCI taps Huawei for innovation center
                                </Text>
                            </Box>

                        </Box>
                    </Link>


                </Grid>

{/* 
                <Modal isOpen={isOpen} onClose={onClose} size="xl">
                    <ModalOverlay />
                    <ModalContent>

                    <ModalCloseButton />
                    <ModalBody pt="50px">
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/DSC01519-min.JPG"   borderRadius="10px 10px 0 0"/>
                    </ModalBody>


                    </ModalContent>
                </Modal> */}
            </Box>

        </Container>

        </>
    )
}