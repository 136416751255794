import  React, {useState, useEffect} from "react";
import { Box, Text, Flex, Button, Drawer, DrawerOverlay, useDisclosure, DrawerContent, DrawerCloseButton, IconButton, Image } from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { ReactComponent as Logo } from '../logo.svg';
import { Link, useLocation } from "react-router-dom";
  

  const MenuItems = (props) => {
    const { children, isLast, to = "/", ...rest } = props
    return (
      <Text
        mb={{ base: isLast ? 0 : 8, sm: 0 }}
        mr={{ base: 0, sm: isLast ? 0 : 8 }}
        fontWeight={500}
        fontSize={["20px", "16px", "16px"]}
        display="block"   
        transition=".8s"     
        {...rest}
    
      >
        <Link _hover={{textDecor: "none"}}  to={to}>{children}</Link>
      </Text>
    )
  }




  
   
const NavBar = (props) => {
    const [show, setShow] = useState(false);
    const toggleMenu = () => setShow(!show);

    const location = useLocation();

    const [pathWhite, setPathwhite] = useState(false);


    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()


    const MenuItemsMob = (props) => {
      const { children, to = "/", isLast } = props
      return (
        <Link to={to}>
        <Button w="300px" m={"10px"} h={12} onClick={onClose} bg={isLast ? "#BA2A2A" : "#EDF2F7"}  color={isLast ? "white" : "black"}  >
          {children}
        </Button>
        </Link>
    
      )
    }
     



    const changeColor = () => {
      if(location.pathname === "/" || location.pathname === "/press" || location.pathname === "/contact" || location.pathname === "/events" || location.pathname === "/vision"){
        setPathwhite(true);
      }
      else{
        setPathwhite(false);
      }
    }


    useEffect(() => {
      changeColor();
    }, [location])
   
    return (

      
      <Flex
        as="nav"
        align="center"
        justifyContent="space-between"
        wrap="wrap"
        w="100%"
        position={pathWhite ? "inherit" : "absolute"}
        zIndex="5"
        px={{md: "80px", base: 4}}
        py={30}
        bg={["primary.500", "primary.500", "transparent", "transparent"]}
        color={pathWhite ? ["black", "black", "black", "black"] : ["white", "white", "primary.700", "primary.700"]}
        {...props}
      >


        <Flex align="left" w={["280px", "280px", "280px", "380px"]}  transition=".8s" justify="left" fontSize="24px" fontWeight="700">
          <Image src="https://d1qwje3ak9jfe8.cloudfront.net/LOGO-PCCI.png" h={["35px", "35px", "35px", "50px"]} />
        </Flex>


      <IconButton aria-label="Search database" onClick={onOpen} display={["block", "block", "block", "none" ]} icon={<HamburgerIcon />}  variant="outline"   colorScheme="white" fontSize="22px" />

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        display={["block", "block", "block",  "none" ]}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton fontSize="20px" right="20px" top="20px" />
              
        <Box mt="60px"> 
            
          <Flex
            align={["center", "center", "center", "center"]}
            justify={["center", "center", "center", "center"]}
            direction={["column", "column", "column", "row"]}
            pt={[4, 4, 0, 0]}
          >




        
              <MenuItemsMob to="/" >Home</MenuItemsMob>
      
            <MenuItemsMob  to="/facility">Facilities</MenuItemsMob>
            <MenuItemsMob  to="/vision">AMBISYON 2040</MenuItemsMob>
            <MenuItemsMob  to="/events">Events</MenuItemsMob>
            <MenuItemsMob  to="/press">Press</MenuItemsMob>
            <MenuItemsMob  to="/partners">Partners</MenuItemsMob>
            {/* <MenuItemsMob  to="/investors">Investors </MenuItemsMob> */}
           

           
            <MenuItemsMob to="/contact" isLast >
              Contact Us
                {/* <Button
                    size="sm"
                    rounded="none"
                    variant="outline"
                    py={5}
                    px={5}
                    border="3px solid"
                    borderColor="#BA2A2A"
                    fontSize="16px"
                    fontWeight="700"
                    transition=".8s"
                    _hover={{ bg: "#BA2A2A", color: "white"}}
                    _focusWithin={{ bg: "#BA2A2A", color: "white" }}
                    color={pathWhite ? "black" : "white"}
                >
                    Contact Us
                </Button> */}
                </MenuItemsMob>
      

         
            

          </Flex>
        </Box>


          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
   
   

   
        <Box
          display={["none", "none", "none", "block" ]}
          flexBasis={{ base: "100%", md: "auto" }}
        >


            
          <Flex
            align={["center", "center", "center", "center"]}
            justify={["center", "center", "center", "center"]}
            direction={["column", "column", "column", "row"]}
            pt={[4, 4, 0, 0]}
          >
      
            <MenuItems to="/">Home</MenuItems>
      

            <MenuItems to="/facility">Facilities</MenuItems>
            <MenuItems to="/vision">AMBISYON 2040</MenuItems>
            <MenuItems to="/events">Events</MenuItems>
            <MenuItems to="/press">Press </MenuItems>
            <MenuItems  to="/partners">Partners</MenuItems>
            <MenuItems isLast to="/contact">Contact Us </MenuItems>


         
            

          </Flex>
        </Box>

 

        {/* <Flex justify={["right", "right", "right", "right"]} w="150px" display={["none", "none", "none", "block" ]}>
            <MenuItems to="/contact" isLast >
                <Button
                    size="sm"
                    rounded="none"
                    variant="outline"
                    py={4}
                    px={5}
                   
                    h="auto"
                    border="3px solid"
                    borderColor="#BA2A2A"
                    fontSize="16px"
                    fontWeight="700"
                    transition=".8s"
                    _hover={{ bg: "#BA2A2A", color: "white"}}
                    _focusWithin={{ bg: "#BA2A2A", color: "white" }}
                    color={pathWhite ? "black" : "white"}
                >
                    Contact Us
                </Button>
                </MenuItems>
        </Flex> */}
      </Flex>
    )
  }


export default NavBar;