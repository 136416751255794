import  React, {useState, useEffect} from "react";
import { Box, Text } from "@chakra-ui/react";
import ImageText from "../Components/ImageText";
import Form from "../Components/Sub-Components/SignupForm";
import Contact from "../Components/Sub-Components/ContactForm";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import InnovatorForm from "../Components/Sub-Components/InnovatorContactsForm";



export default function ContactUs(){
    return(
        <Box pt={"60px"}>
{/* 

        <Tabs variant="soft-rounded" colorScheme="red">
        <TabList justifyContent="center">
            <Tab>Contact Us</Tab>
            <Tab>Innovator Application</Tab>
        </TabList>
        <TabPanels>
            <TabPanel>
                <Contact title="Contact Us"/>
            </TabPanel>
            <TabPanel> */}
                <InnovatorForm title="Contact Us"/>
            {/* </TabPanel>
        </TabPanels>
        </Tabs>
            */}
        </Box>
    )
}