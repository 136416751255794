import './App.css';
import React, { useEffect, useState } from "react";
import ComingSoon from './Pages/comingsoon';
import HomePage from './Pages/home';
import NavBar from './Components/Navbar';
import {
  Switch,
  Route
} from "react-router-dom";
import Facility from './Pages/facility';
import Footer from './Components/Footer';
import Innovators from './Pages/innovators';
import Investors from './Pages/investors';
import Partners from './Pages/partners';
import ContactUs from './Pages/contactus';
import AOS from 'aos';
import "aos/dist/aos.css";
import Events from './Pages/events';
import Vision from './Pages/visions';
import Press from './Pages/press';



function App() {






  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>



      <NavBar/>

      <Switch>


 

  
      <Route path="/" exact>
        <HomePage/>
      </Route>


      <Route path="/vision">
        <Vision/>
      </Route>


      <Route path="/facility">
        <Facility/>
      </Route>


      <Route path="/events">
        <Events/>
      </Route>


      <Route path="/press">
        <Press/>
      </Route>
    

      <Route path="/partners">
        <Partners/>
      </Route>
    


      <Route path="/contact">
        <ContactUs/>
      </Route>
    


    </Switch>

    <Footer/>
      {/* <ComingSoon/> */}
    </>
  );
}

export default App;
