import React from 'react';
import HeroFacility from '../Components/HeroFacility';
import FourSection from '../Components/Sub-Components/FourSection';
import FullCarousel from '../Components/Sub-Components/FullCarousel';
import InteractiveImage from '../Components/Sub-Components/InteractiveImages';
import TextImageIcons from '../Components/TextImageIcon';


export default function Facility(){
    return(
        <>
            <HeroFacility image="https://d1qwje3ak9jfe8.cloudfront.net/DSC01142-min.JPG" />

            {/* <TextImageIcons 
            tagline="Manila, Philippines"
            title="ABOUT THE CENTER"
            image="https://d1qwje3ak9jfe8.cloudfront.net/DSC01140-min.JPG" 
            /> */}

            <InteractiveImage/>
            {/* <FullCarousel/> */}
            <FourSection/>
        </>
    )
}