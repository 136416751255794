import  React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import ImageText from "../Components/ImageText";
import Form from "../Components/Sub-Components/SignupForm";
import HeroPartners from "../Components/HeroPartners";
import TextImagePartner from "../Components/TextImagePartner";
import FullCarouselPartners from "../Components/Sub-Components/FullCarouselPartners";




const slides = [
    { id: 0, url: 'https://d1qwje3ak9jfe8.cloudfront.net/DSC01458-min.JPG' },
    { id: 1, url: 'https://d1qwje3ak9jfe8.cloudfront.net/DSC01570-min.JPG' },
    { id: 2, url: 'https://d1qwje3ak9jfe8.cloudfront.net/DSC01574-min.JPG' },
    { id: 3, url: 'https://d1qwje3ak9jfe8.cloudfront.net/DSC01600-min.JPG' },
  ]




export default function Partners(){





    

    return(
        <>
            <Box>
                <HeroPartners image="https://d1qwje3ak9jfe8.cloudfront.net/DSC01519-min.JPG"/>
                <TextImagePartner title="HUAWEI" 
                tagline="Partner"
                image="https://d1qwje3ak9jfe8.cloudfront.net/HUAWEI.png"  
                text={<div>Being a leading global provider of information and communications technology, HUAWEI has partnered up with innovate.com.ph to increase Filipino proficiency in their knowledge of programming and development of AI products. With this in mind, HUAWEI’s mission is to enable the Philippines to join the 4th Industrial Revolution (Industry 4.0). Promising individuals are also invited to tour and receive specialized training in HUAWEI’s facilities in Shenzhen. With this in mind, HUAWEI seeks to encourage the Philippines to develop its AI ecosystem and support growth in our local technology industry. </div>}  />
                <TextImagePartner title="PCCI"
                isLastElement={true}
                tagline="Partner"
                image="https://d1qwje3ak9jfe8.cloudfront.net/PCCI.jpg"  
                text={<div>The Philippine Chamber of Commerce and Industry (PCCI) is the largest business organization in the country. It is a non-stock, non-profit, non-government business organization comprised of small, medium, and large enterprises, local chambers and industry associations. These represent various sectors of business, all working together to foster a healthier Philippine economy and to improve the viability of business in the community.</div>}  />
                {/* <TextImagePartner title="DICT"
                tagline="Partner"
                image="https://d1qwje3ak9jfe8.cloudfront.net/Screen Shot 2021-02-05 at 9.09.17 PM.png"  
                text={<div>The Department of Information and Communications Technology aims to provide Filipinos with access to essential ICT infostructure and services. The organization is dedicated to push the country’s development and progress towards a leading- edge economy.</div>}  />
                <TextImagePartner title="TESDA" 
                tagline="Partner"
                image="https://d1qwje3ak9jfe8.cloudfront.net/TESDA.png"  
                text={<div>The Technical Education and Skills Development Authority (TESDA) is a government agency  which manages and supervises technical education and skills development in the Philippines. TESDA seeks to implement programs geared towards quality-assurance, technical education, and skills development.</div>}  />
                <TextImagePartner title="NEDA" isLastElement={true}
                tagline="Partner"
                image="https://d1qwje3ak9jfe8.cloudfront.net/LAST.png"  
                text={<div>The National Economic and Development Authority (NEDA) is the country’s premier socioeconomic planning body, highly regarded as the authority in macroeconomic forecasting and policy analysis and research. NEDA reviews, evaluates, and monitors infrastructure projects identified under the Comprehensive and Integrated Infrastructure Program. It also acts as an adviser to policymakers in the country through the Philippine Congress and Executive Branch. </div>}  /> */}
                <FullCarouselPartners/>
            </Box>

        </>
    )
}