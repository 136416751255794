import React from 'react';
import { Container, Box, Grid, Image, Text } from "@chakra-ui/react";


export default function FourSection(){
    return(
        <>
            <Container maxW={["92vw", "92vw", "84vw"]} p={["0px", "1rem", "1rem"]}>

                <Box py={{base: "90px", md: "90px"}}>
                    <Grid templateColumns={{sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}} gap={8}>
                        <Box pt={4}>
                            <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Meridian_Hall(New)-min.jpg" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Meridian hall
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Our conference hall is equipped to accommodate over 100 people. Meridian Hall is prepared to facilitate events, meetings/zoom meetings, seminars, and new product launches. Here, lecturers and special guests will cover topics in both the business and technology sectors. 
                            </Text>
                        </Box>
                        <Box pt={4}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/steve.jpg" w="100%" h="346px"  htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Jobs Conference Room
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Traditionally available only in a corporate setting, the innovation center seeks to provide a professional environment while maintaining the elements of creativity and collaboration. We equipped our conference rooms with TVs, conferencing cameras, ethernet ports, and adjustable barriers.
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Branson Lounge-min.jpeg" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Branson Lounge
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Situated beside the Coffee Bar, Branson Lounge is a gathering place fit for socializing and networking. 
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Ben_Dimson.jpg" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Ben Dimson Discussion Room
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Equipped with large dry erase boards, Ben Dimson allows innovators to sketch out their ideas and share them with their colleagues.
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Wright Brothers 2-min.jpeg" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                               Wright Brothers Conference Room
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Conference rooms: Traditionally available only in a corporate setting, the innovation center seeks to provide a professional environment while maintaining the elements of creativity and collaboration. We equipped our conference rooms with TVs, conferencing cameras, ethernet ports, and adjustable barriers.
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Bezos-min.jpeg" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Bezos Workspace
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                The PCCI Innovation Center has specially organized workspaces allow innovators to meet and work with their team in a much more collaborative office environment. Spacious and well equipped, our workspaces are designed to maximize productivity.
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Elon_Workspace-min.jpg" w="100%"  h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Elon Workspace
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                The PCCI Innovation Center has specially organized workspaces allow innovators to meet and work with their team in a much more collaborative office environment. Spacious and well equipped, our workspaces are designed to maximize productivity.
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Edison_Thinkspace_1_(2)-min.jpg" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Edison Thinkspace 1
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                A small gathering place for innovators and entrepreneurs to meet with their teams and work in an informal setting.
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Edison_Thinkspace_2_(1)-min.jpg" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Edison Thinkspace 2
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                A small gathering place for innovators and entrepreneurs to meet with their teams and work in an informal setting.
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Einstein_2-min.jpg" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Einstein Workspace
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                The PCCI Innovation Center has specially organized workspaces allow innovators to meet and work with their team in a much more collaborative office environment. Spacious and well equipped, our workspaces are designed to maximize productivity.
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/DSC01371-min.JPG" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Maker Lab
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Provides users with machinery and equipment to assist in rapid prototyping. The lab has several microcontroller kits and microprocessor kits such as Arduino, Raspberry Pi, and more which would cater to startups in the beginning stages of their first projects. This space is equipped with PCs with creation software already installed for designing 3D models Once these designs are made, 3D printers are ready to assist in creating physical prototypes of these software-designed components and products.
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/DSC01218-min.JPG" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Coffee Bar
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                The Coffee Bar is a gathering place for innovators to socialize and network with their peers over a hot cup of coffee. 
                            </Text>
                        </Box>
                        <Box pt={8}>
                        <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Roof Deck Lounge 1-min.JPG" w="100%" h="346px" htmlHeight="346px" objectFit="cover" />
                            <Text 
                            data-aos="fade"
                            fontSize="25px" 
                            fontWeight={900}
                            py={4}>
                                Roof Deck Lounge
                            </Text>
                            <Text 
                            data-aos="fade"
                            fontSize="14px"
                            lineHeight="30px"
                            color="#4F4F4F">
                                Our guests and visitors can enjoy a scenic view of Manila from our Roof Deck Lounge
                            </Text>
                        </Box>
                    </Grid>
                </Box>
                
            </Container>
          
        </>
    )
}