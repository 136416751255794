import React, { useEffect, useState } from 'react';
import { Image, Text, Box, Container, Grid } from "@chakra-ui/react";
// import { useTransition, animated, config } from 'react-spring';





export default function BoxContainerPartner(props){
    // const [index, set] = useState(0)


    // const transitions = useTransition(props.slides[index], item => item.id, {
    //     from: { opacity: 0 },
    //     enter: { opacity: 1 },
    //     leave: { opacity: 0 },
    //     config: config.molasses,
    //   })

    //   useEffect(() => void 
    //     setInterval(() => set(state => (state + 1) % 4), 5000)
    //   , [])


  

    return(
        <Box pt={props.isLastElement ? {base: "0px", md: "0px"} : {base: "90px", md: "90px"}} pb={props.isLastElement && {base: "90px", md: "90px"} } >
            <Grid templateColumns={[ "repeat(1, 1fr)","repeat(1, 1fr)","repeat(1, 1fr)", "2fr fit-content(56%)"]} gap={10} h={["auto", "auto" ,"500px"]}>
                <Box  pos="relative">


                {/* { transitions.map(({ item, props, key }) => (
                    <animated.div
                    key={key}
                 
                    style={{ ...props, 
                        backgroundImage: `url(${item.url})`, 
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        objectFit: "cover",
                        willChange: "opacity",
                        backgroundSize: "cover",
                        backgroundPosition: "cover"
                     }}
                    />
                ))} */}



                    <Image src={props.image} p={["60px", "60px", "90px"]} display={["block", "none", "none", "block"]} w="100%" objectFit="cover" /> 
                </Box>
            
                <Box m="auto 0" data-aos="fade">
                    <Text color="#BA2A2A" fontWeight={500} fontSize="19px" pb={4}>
                        {props.tagline}
                    </Text>
                    <Text color="#000000" fontWeight={900} fontSize={["27px", "36px", "36px"]} lineHeight="50px" pb={6} w="80%">
                        {props.title}
                    </Text>
                    <Text color="#4F4F4F" fontWeight={500} fontSize="14px" lineHeight="30px" w={["100%", "95%" ,"95%"]}>
                        {props.text}
                    </Text>
                </Box>

            </Grid>
        </Box>
    )
}