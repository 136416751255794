import React from 'react';
import { Image, Text, Box, Container, Grid } from "@chakra-ui/react";





export default function BoxContainer(props){
    return(
        <Box py={{base: "90px", md: "90px"}}>
            <Grid templateColumns={[ "repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={10}>
            
                <Box m="auto 0"  data-aos="fade">
                    <Text color="#BA2A2A" fontWeight={500} fontSize="19px" pb={4}>
                        {props.tagline}
                    </Text>
                    <Text color="#000000" fontWeight={900} fontSize={["27px", "36px", "36px"]} lineHeight="50px" pb={6} w="80%">
                        {props.title}
                    </Text>
                    <Text color="#4F4F4F" fontWeight={500} fontSize="14px" lineHeight="30px" w={["100%", "95%" ,"95%"]}>
                        {props.text}
                    </Text>
                </Box>
                <Box h={["486px", "540px", "540px", "540px"]}>
                    <Image src={props.image} h="100%" objectFit="cover" />
                </Box>

            </Grid>
        </Box>
    )
}