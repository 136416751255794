import React, { useState, useEffect } from 'react';
import { useTransition, animated, config } from 'react-spring';





const slides = [
    { id: 0, url: 'https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9763-min.jpg' },
    { id: 1, url: 'https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9784-min.jpg' },
    { id: 2, url: 'https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9791-min.jpg' },
  ]

export default function FullCarouseEvents(){

    const [index, set] = useState(0)


    const transitions = useTransition(slides[index], item => item.id, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
      })

      useEffect(() => void 
        setInterval(() => set(state => (state + 1) % 3), 5000)
      , [])



    return(
        <div style={{position: "relative", height: "660px", backgroundColor: "black"}} >

                { transitions.map(({ item, props, key }) => (
                    <animated.div
                    key={key}
                 
                    style={{ ...props, 
                        backgroundImage: `url(${item.url})`, 
                        width: "100%",
                        height: "660px",
                        position: "absolute",
                        objectFit: "cover",
                        willChange: "opacity",
                        backgroundSize: "cover",
                        backgroundPosition: "center", 
                        borderRadius: "10px"
                     }}
                    />
                ))}
        </div>
    )
}