import React from 'react';
import { Container } from "@chakra-ui/react";
import BoxContainerPartner from "./Sub-Components/BoxContainerPartner";












export default function TextImagePartner(props){
    return(
        <>
            <Container maxW={["92vw", "92vw", "84vw"]} p={["0px", "1rem", "1rem"]}>
                <BoxContainerPartner tagline={props.tagline} title={props.title} text={props.text} image={props.image} slides={props.slides} isLastElement={props.isLastElement}/>
            </Container>
  
        </>
    )
}