import  React, { useState } from "react";
import { Box, Container, Grid, Text, GridItem, Input, Button, useToast, Textarea } from "@chakra-ui/react";
import emailjs from 'emailjs-com';



export default function InnovatorForm(props){


    const toast = useToast();
    const initialValues = {
        fullName: '',
        phone: '',
        email: '',
        company: '', 
        message: '', 
        // type: props.type
    }


    const [values, setValues] = useState(initialValues);
    const [isLoading, setLoading] = useState(false);



    const handleChange = e => {
        var { name, value } = e.target;
        setValues({
            ...values, 
            [name]: value,
        })

    }

    const sendEmail = async(e, props) => {
        e.preventDefault();
    
        setLoading(true)


        await emailjs.send('innovate_ph', 'template_rr8qcxn', {from_email: values.email, from_name: values.fullName, from_phone: values.phone, from_message: values.message, from_company: values.company}, 'user_75Bsee9ECUBAtheNEzbIL')
        .then((result) => {
            console.log('Email Sent!');
        }, (error) => {
            console.log(error.text);
        });

        console.log("done");

    
        toast({
          title: "Signed Up.",
          description: "Thank you for your interest.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
    
        setLoading(false);
    
        setValues({...initialValues});
        e.target.reset();
    
      }




    return(
        <>
            <Box pb={"200px"} pt={"30px"}>
                <Container>
                    <Text 
                    fontSize="36px"
                    fontWeight={900}
                    textAlign="center"
                    pb={10}>
                        {props.title}
                    </Text>
                    <form onSubmit={sendEmail} className="form">
                    <Grid templateColumns="repeat(2, 1fr)"  templateRows="repeat(4, 1fr)" gap={"10px 20px"}>
                        <GridItem  >
                            <Input variant="filled"  value={values.fullName} onChange={handleChange} type="text"  name="fullName" placeholder="Full Name" py={7} _focus={{ borderColor: "#ba2a29", backgroundColor: "transparent" }} />
                        </GridItem>
                        <GridItem   >
                            <Input variant="filled" value={values.phone} onChange={handleChange}  type="tel"  name="phone" placeholder="Phone Number" py={7} _focus={{ borderColor: "#ba2a29", backgroundColor: "transparent" }} />
                        </GridItem>
                        <GridItem colSpan={2}    >
                            <Input variant="filled" value={values.email} onChange={handleChange}  type="email"  name="email" placeholder="Email" py={7} _focus={{ borderColor: "#ba2a29", backgroundColor: "transparent" }} />
                        </GridItem>
                        <GridItem colSpan={2}   >
                            <Input variant="filled" value={values.company} onChange={handleChange}  type="text"  name="company"  placeholder="Company Name (Optional)" py={7} _focus={{ borderColor: "#ba2a29", backgroundColor: "transparent" }} />
                        </GridItem>
                        <GridItem colSpan={2}   >
                            <Textarea variant="filled" value={values.message} onChange={handleChange}  type="text"  name="message"  placeholder="Write message here..." py={7} _focus={{ borderColor: "#ba2a29", backgroundColor: "transparent" }} />
                        </GridItem>
                        <GridItem colSpan={2}  pt={4}  >
                            <Button  
                            isLoading={isLoading}  
                            disabled={!values.email, !values.fullName, !values.phone, !values.message}
                            type="submit"
                            bg="#BA2A2A"
                                _hover={{ bg: "#A72222" }}
                                _focusWithin={{ bg: "#921D1D" }}
                                size="md" 
                                color="white"
                                py={7} 
                                w={"full"} >
                                Submit
                            </Button>
                        </GridItem>
                    </Grid>
                    </form>
                </Container>

            </Box>


        </>
    )
}