import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Avatar, Container, Image, Text, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCouch, 
    faChalkboardTeacher, 
    faUsers, 
    faUserFriends, 
    faComments, 
    faBriefcase, 
    faLaptopHouse, 
    faLightbulb, 
    faBookReader, 
    faCogs,
    faCoffee,
    faNetworkWired   } from '@fortawesome/free-solid-svg-icons';





export default function InteractiveImage(){
    return(
        <Container maxW={["92vw", "92vw", "84vw"]} p={["0px", "1rem", "1rem"]} >
            <Tabs variant="soft-rounded" colorScheme="red" pt="30px" >
            <Box  overflowY="auto"
                p="5px"
    
                css={{
                    '&::-webkit-scrollbar': {
                    width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                    width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                    borderRadius: '24px',
                    },
                }}>
            <TabList textAlign="center" margin="0 auto" justifyContent={["end", "end", "end", "center"]} >
                <Tab>
                    <FontAwesomeIcon icon={faChalkboardTeacher} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faUsers} size="2x" style={{padding: "3px"}} />
                    
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faCouch} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faComments} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faUserFriends} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faBriefcase} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faLaptopHouse} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faLightbulb} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faLightbulb} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faBookReader} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faCogs} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faCoffee} size="2x" style={{padding: "3px"}} />
                </Tab>
                <Tab>
                    <FontAwesomeIcon icon={faNetworkWired} size="2x" style={{padding: "2px"}} />
                </Tab>
               
            </TabList>
            </Box>
            <TabPanels  mt={4}>
                <TabPanel pos="relative" p="0" >
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Meridian_Hall(New)-min.jpg" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%" />
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Meridian hall
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/steve.jpg" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%" />
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Jobs Conference Room
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Branson Lounge-min.jpeg" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Branson Lounge
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Ben_Dimson.jpg" borderRadius="10px" objectFit="cover"  h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Ben Dimson Discussion Room
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Wright Brothers 2-min.jpeg" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Wright Brothers Conference Room
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Bezos-min.jpeg" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Bezos Workspace
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Elon_Workspace-min.jpg" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Elon Workspace
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Edison_Thinkspace_1_(2)-min.jpg" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Edison Thinkspace 1
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Edison_Thinkspace_2_(1)-min.jpg"  borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Edison Thinkspace 2
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Einstein_2-min.jpg" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Einstein Workspace
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/DSC01371-min.JPG" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Maker Lab
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/DSC01218-min.JPG" borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Coffee Bar
                    </Text>
                </TabPanel>
                <TabPanel pos="relative" p="0">
                    {/* <Box pos="absolute"  backgroundImage="linear-gradient(45deg, black, transparent, transparent)"  w="full" h={["445px", "445px", "445px", "645px" ]} borderRadius="10px"/> */}
                    <Image src="https://d1qwje3ak9jfe8.cloudfront.net/Roof Deck Lounge 1-min.JPG"  borderRadius="10px" objectFit="cover" h={["445px", "445px", "445px", "645px" ]} w="100%"/>
                    <Text pos="absolute" color="white" bottom="40px" padding="30px" fontSize={["22px", "22px", "22px", "30px" ]} fontWeight="900">
                        Roof Deck Lounge
                    </Text>
                </TabPanel>
            </TabPanels>
            </Tabs>
        </Container>
    )
}