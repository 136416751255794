import React from 'react';
import { Box, Container, Grid, Image, Text } from '@chakra-ui/react';


export default function Vision(){
    return(
        <>
        <Box background="#125394" pb="95px">
           <Container maxW={["92vw", "92vw", "84vw"]} p={["0px", "1rem", "1rem"]}>

               <Box >
                <Image src="https://d1qwje3ak9jfe8.cloudfront.net/vision.png" py={[10, 10, 10, 12]} w={["80%","80%","80%","40%"]}  margin="0 auto"/>
               </Box>

               <Grid templateColumns={[ "repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={16} px={[0, 0, 0, 16]}>
                   <Box>
                       <Text fontSize="36px" color="white" fontWeight="900">
                         Vision: Ambisyon 2040
                       </Text>
                       <Text fontSize="14px" color="white" fontWeight="500" lineHeight="30px" py={4}>
                        A collective long-term vision of the Philippines where Filipinos enjoy a stable and comfortable life where no one is poor or hungry. The Philippines becomes a prosperous middle-class society where people live long and healthy lives and are smarter and innovative. This goal envisions the country inhabited by well-educated Filipinos that have the character qualities to continuously better themselves, their fellow countrymen, and the quality of life in the Philippines. 
                        </Text>

                   </Box>
                   <Box>

                        <Text fontSize="36px" color="white" fontWeight="900">
                            Mission
                       </Text>
                       <Text fontSize="14px" color="white" fontWeight="500" lineHeight="30px" py={4}>
                        The mission of the PCCI Innovation Center is to promote the development of innovation technologies in the Philippines and cultivate the Philippine technology ecosystem by making innovators and entrepreneurs more equipped in their chosen fields. We aim to create a digitally transformed country which can harness the power of Smart Cities, Internet of Things, and AI to realize the objectives of Ambisyon 2040
                       </Text>

                   </Box>

               </Grid>




            </Container>
        </Box>
        </>
    )
}