import { Image, Grid, Box, Link, Text } from "@chakra-ui/react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Airtable from 'airtable';
import React, { useEffect, useState } from "react";
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox-pro'




export default function Album() {


    const images = [
        // {
        //     name: "Satellite Internet Connectivity Meeting - 03/17/2021",
        //     children: [
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0342-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0347-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0348-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0351-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0353-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0355-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0357-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0358-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0361-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SICM/IMG_0362-min.jpg"},
        //     ]
        // },
        // {
        //     name: "SDG 101 - 03/12/2021",
        //     children: [
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0147-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0159-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0160-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0163-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0179-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0182-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0185-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0187-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0206-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/SDG101/IMG_0218-min.jpg"},
        //     ]
        // },
        // {
        //     name: "Meeting with the General Manager of Microsoft - Mr. Andres Ortola - 03/10/2021",
        //     children: [
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/Album cover image -min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0033-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0047-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0053-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0054-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0056_jpg-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0060-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0062-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0066-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0067-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0081-min.jpg"},
        //         {url: "https://d1qwje3ak9jfe8.cloudfront.net/GMMicro/IMG_0079-min.jpg"},
        //     ]
        // },
        {

            
            name: "PCCI and Embiggen Webinar - 03/03/2021",
            children: [
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/album cover image-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9763-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9784-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9791-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9793-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9828-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9855-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9859-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/PCCIxE/IMG_9861-min.jpg"},

            ]
        },
        {

            
            name: "India Embassy Visit - 02/26/2021",
            children: [
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IndiaE/album cover image-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IndiaE/IMG_9616-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IndiaE/IMG_9640-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IndiaE/IMG_9641-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IndiaE/IMG_9648-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IndiaE/IMG_9651-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IndiaE/IMG_9654-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IndiaE/IMG_9656-min.jpg"},

            ]
        },
        {

            
            name: "DTI Ocular Visit - 02/24/2021",
            children: [
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/DTI/album cover image-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/DTI/IMG_9567-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/DTI/IMG_9551-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/DTI/IMG_9548-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/DTI/IMG_9537-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/DTI/IMG_9530-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/DTI/IMG_9525_jpg-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/DTI/IMG_9520-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/DTI/IMG_1598-min.jpg"},

            ]
        },
        {

            
            name: "First PCCI General Membership Meeting - 02/18/2021",
            children: [
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/FirstPCCI/album cover image-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/FirstPCCI/_MG_4121-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/FirstPCCI/_MG_4119-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/FirstPCCI/_MG_4111-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/FirstPCCI/_MG_4108-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/FirstPCCI/_MG_4090-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/FirstPCCI/_MG_4075-min.jpg"},

            ]
        },
        {

            
            name: "Embassy of Israel Visit - 02/17/2021",
            children: [
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IsraelE/Album cover image-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IsraelE/IMG_9247-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IsraelE/IMG_9254-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IsraelE/IMG_9261-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IsraelE/IMG_9267-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IsraelE/IMG_9276-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IsraelE/IMG_9278-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IsraelE/IMG_9282-min.jpg"},
                {url: "https://d1qwje3ak9jfe8.cloudfront.net/IsraelE/IMG_9311-min.jpg"},

            ]
        }
    ]



    const base = new Airtable({ apiKey: 'keyKU4TTFvNzdz8vZ' }).base('appwbfS7eGE3llMkk');
    const [pics, setPics] = useState([]);

  
  
  
    useEffect(() => {

    
        base('Images').select({view: 'Grid view'})
        .eachPage((records, fetchNextPage) => {
            let tempArray = [];
            records.map((record) => {
                tempArray.push(record._rawJson.fields); 
                console.log(record.fields.Attachments.length);
                              
            });
            console.log(tempArray);
            setPics(tempArray);
        })
        
    }, [])




  return (
    <>

        <Box>
          

            {
                pics.map((pic, index) => (
                    
                    <>


                    <SimpleReactLightbox>
                        <SRLWrapper>
                            <Text 

                            key={index}
                                        
                            fontSize="25px"
                            fontWeight={900}
                            pb={6}
                            pt={14}
                            >
                                {pic.Name}
                            </Text>


                    <Grid templateColumns={["repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"]} gap={6}>
                                
                    {
                    
                        pic.Attachments.length > 0 && pic.Attachments.map((image, index) => (
                            <>
                            
                                <Link href={image.url}  key={index}>
                                        <Image src={image.url}  h="180px" objectFit="cover" />
                                </Link>
                    

                            </>
                        ))
                    }
                    </Grid>




                    </SRLWrapper>
                </SimpleReactLightbox>
                        
                    </>
                ))
            }

        
        </Box>


        {/* <Box>

            <SimpleReactLightbox>

            <SRLWrapper>

                { images.map((album, index) => (
                        <>
                        <Text 
                            key={index}
                            fontSize="25px"
                            fontWeight={900}
                            pb={6}
                            pt={14}
                            >

                                {album.name}
                            </Text>

                            <Grid templateColumns={["repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"]} gap={6}>
                                        
                                     
                            { album.children.map((image) => (

                                <>

                           <Link href={image.url} >
                                            <Image src={image.url}  h="180px" objectFit="cover" />
                                        </Link>

                                 

                                </>

                                ) )
                            }

                            </Grid>

                        </>
                    ))
                }

 

 

                
            </SRLWrapper>
            




            </SimpleReactLightbox>


        </Box> */}


    



    </>
  );
}

